<template>
  <div class="home">
    <!--    轮播图-->
    <div class="block">
          <el-image
              class="shuffling"
              :src="Contactus">
          </el-image>
    </div>
    <div class="Contactus">
      <div class="MapInformation">
        <el-row>
          <el-col :md="19" :xs="24">
            <baidu-map class="map"  :center="{lng: 112.568756, lat: 37.810884}"  :zoom="16" >
              <bm-marker :position="{lng: 112.568756, lat: 37.810884}" :dragging="false">
              </bm-marker>
            </baidu-map>
          </el-col>
          <el-col :md="5" :xs="24">
            <div class="ContactUs-right">
<!--              <div class="ContactUs-right-img">-->
<!--                <div class="ContactUs-image">-->
<!--                  <el-image-->
<!--                      style="width: 100%; height: 100%"-->
<!--                      :src="gzh">-->
<!--                  </el-image>-->
<!--                </div>-->
<!--                <span class="ContactUs-text">翊泽微信公众号</span>-->
<!--              </div>-->
              <div>
                <div class="ContactUs-image">
                  <el-image
                      style="width: 100%; height: 100%"
                      :src="wx">
                  </el-image>
                </div>
                <span class="ContactUs-text">翊泽公司微信</span>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <!--    footer-->
    <Footer/>
  </div>
</template>
<script>
// @ is an alias to /src
import Footer from "../components/footer"
export default {
  name: 'Home',
  components: {
    Footer
  },
  data(){
    return{
      Contactus:require("../assets/image/Contactus1.jpg"),
      gzh:require("../assets/image/gzh.jpg"),
      wx:require("../assets/image/wx.jpg"),
    }
  },
  mounted() {
    // 获取位置信息
  },


}
</script>
<style scoped>

.shuffling{
  width: 100%;
  max-height:500px ;
}
.home-NavMenu{
  margin: 0 auto;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
}
.block{
  margin: 0;
  max-height: 500px;
}


.CompanySynopsis>div{
  margin-right: 20px;
}
.CompanySynopsis div{
  font-size: 15px;
  color: #708090;
  line-height: 30px
}

.map {
  width: 782px;
  height:423px;
  max-width: 782px;
  max-height: 423px;
  margin: 0 auto;
}
.MapInformation{
  max-width: 990px;
  display: flex;
  margin: 0 auto;
  padding: 20px;
}

.MainBusiness-image .el-image{
  width: 40px;
  height: 40px;
  margin-top: 5px;
}
.Contactus{
  margin-bottom: 20px;
}
.ContactUs-right{
  margin-left: 20px;
  text-align: center;
}
.ContactUs-right-img{
  margin-bottom: 10px;
}
.ContactUs-image{
  width: 185px;
  height: 185px;
}
.ContactUs-text{
  text-align: center;
  margin: 0 auto;
}
@media only screen and (max-width:991px){

  .ContactUs-right{
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  .ContactUs-right-img{
    margin-right: 20px;
  }
}
@media only screen and (max-width:796px){
  .map {
    width: 600px;
    height:423px;
  }
  .ContactUs-right{
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  .ContactUs-right-img{
    margin-right: 20px;
  }
}
@media only screen and (max-width:621px){
  .map {
    width: 500px;
    height:423px;
  }
}
@media only screen and (max-width:521px){
  .map {
    width: 420px;
    height:423px;
  }
}
@media only screen and (max-width:421px){
  .map {
    width: 300px;
    height:423px;
  }
  .ContactUs-right-img{
    margin-right: 10px;
  }
  .ContactUs-image{
    width: 120px;
    height: 120px;
  }

}
</style>
