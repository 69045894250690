<template>
<div style="color: #232B2B">
  <div class="carousel-back">
    <div class="carousel">
      <div class="carousel-text">
        荣誉资质
      </div>
      <el-carousel class="carousel-Honor1" type="card"  height="500px">
        <el-carousel-item v-for="(item,index) in CompanyList" :key="index">
          <img class="carousel-img" :src="item.img">
        </el-carousel-item>
      </el-carousel>
      <el-carousel class="carousel-Honor2" direction="vertical" height="500px">
        <el-carousel-item v-for="(item,index) in CompanyList" :key="index">
          <img class="carousel-img" :src="item.img">
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
  <!--    footer-->
  <Footer/>
</div>
</template>

<script>
import Footer from "../components/footer"
export default {
  name: "CompanyHonor",
  components:{
    Footer
  },
  data(){
    return{
      CompanyList:[
        {
          img:require("../assets/image/z1.jpg"),
        },
        {
          img:require("../assets/image/z2.jpg"),
        },
        {
          img:require("../assets/image/z3.jpg"),
        },
        {
          img:require("../assets/image/z4.jpg"),
        },
        {
          img:require("../assets/image/z5.jpg"),
        },
        {
          img:require("../assets/image/z6.jpg"),
        },
        {
          img:require("../assets/image/z7.jpg"),
        },
      ]
    }
  }
}
</script>

<style scoped>
.carousel{
  width: 700px;
  margin: 0 auto;
}
.carousel-back{
  padding: 20px 0;
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  background-image: url("../assets/image/shan.jpg");
}
.carousel-img{
  width:100%;
  height: 500px;
  margin: 0 auto;
}
.carousel-text{
  color: #ffffff;
  font-weight: 700;
  font-size: 30px;
  text-align: center;
  position: relative;
  width: 200px;
  margin: 0 auto 20px;
}
.carousel-text::before{
  content: '';
  position: absolute;
  width: 100px;
  height: 3px;
  left: -40%;
  top:40%;
  background:linear-gradient(244deg,rgba(255,255,255,0) 0%,rgba(255,255,255,1) 50%,rgba(255,255,255,0) 100%);
}
.carousel-text::after{
  content: '';
  position: absolute;
  width: 100px;
  height: 3px;
  right: -40%;
  top:40%;
  background:linear-gradient(244deg,rgba(255,255,255,0) 0%,rgba(255,255,255,1) 50%,rgba(255,255,255,0) 100%);
}
@media only screen and (max-width: 710px){
  .carousel-Honor1{
    display: none;
  }
  .carousel-img{
    width:400px;
    display: block;
  }
  .carousel{
    width: 600px;
    max-width: 600px;
  }
}
@media only screen and (max-width: 610px){
  .carousel-Honor1{
    display: none;
  }
  .carousel-img{
    width:400px;
    display: block;
  }
  .carousel{
    width: 400px;
    max-width: 600px;
  }
}
@media only screen and (max-width: 410px){
  .carousel-Honor1{
    display: none;
  }
  .carousel-img{
    width:300px;
    display: block;
  }
  .carousel{
    width: 300px;
    max-width: 600px;
  }
}
@media only screen and (min-width: 711px){
  .carousel-Honor2{
    display: none;
  }
}
</style>
