<template>
  <div class="home">
    <!--    轮播图-->
    <div class="block">
          <el-image
              class="shuffling"
              :src="Aboutus">
          </el-image>
    </div>

    <!--    公司介绍-->
    <div class="Company">
      <div class="CompanySynopsis">
        <el-row :gutter="10">
          <el-col :xs="24" :sm="24" style="display: flex;justify-content: center">
            <div class="CompanySynopsis-image">
              <el-image
                  style="width: 100%; height: 270px"
                  :src="Company">
              </el-image>
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" >
            <div class="CompanySynopsis-text">
              <p>
                山西翊泽科技有限公司是一家专注于办公设备、计算机批发以及矿山科技创新化服务的企业。作为行业中的一员，我们坚持把控质量、客户至上的原则，为广大客户提供高品质的产品和全方位的解决方案。

                我们的办公设备涵盖了打印机、复印机、传真机等多种产品，通过不断引进先进技术和设备，为客户提供高效、可靠的办公解决方案。无论您是小型企业还是大型机构，我们都能根据您的需求量身定制最合适的方案，让您的办公效率得到极大提升。

                作为计算机批发商，我们与多家知名品牌合作，提供各类计算机产品，包括台式机、笔记本、服务器等，以满足不同客户的需求。我们拥有一支经验丰富的团队，能够为客户提供个性化的产品选择和专业的服务。

                此外，我们还专注于矿山科技创新化服务，通过引入先进的技术和设备，为矿山行业提供智能化解决方案。我们的专业团队将充分了解您的需求，并根据实际情况提供可行的解决方案，提高矿山生产效率和安全性。

                无论您是需要办公设备、计算机产品，还是寻求矿山科技创新化服务，山西翊泽科技有限公司都将是您的可靠合作伙伴。我们将坚持以客户为中心的原则，不断创新和优化我们的产品与服务，为客户创造更大的价值。让我们携手共进，共创美好未来！
              </p>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <!--    footer-->
    <Footer/>
  </div>
</template>
<script>
// @ is an alias to /src
import Footer from "../components/footer"
export default {
  name: 'Home',
  components: {
    Footer
  },
  data(){
    return{

      Company:require("../assets/image/Company.jpg"),
      Aboutus:require("../assets/image/Aboutus1.jpg"),

      enterpriseCulture:require("../assets/image/enterpriseCulture.jpg"),
      enterpriseCultureList:[
        {
          image:require("../assets/image/MainBusiness1.png"),
          name:'企业愿景',
          text:'为客户、员工、股东、合作伙伴提供创造和实现他们美好梦想的机会',
          back:'#D7583D'
          // text:'体现自我价值，共创美好生活'
        },
        {
          image:require("../assets/image/MainBusiness2.png"),
          name:'企业使命',
          text:'体现自我价值，共创美好生活',
          back:'#F5C05E'
        },
        {
          image:require("../assets/image/MainBusiness3.png"),
          name:'企业价值观',
          text:'诚信、尊重、谦逊、高标准',
          back:'#EF8A41'
        }
      ]
    }
  },
  mounted() {
    // 获取位置信息
  },


}
</script>
<style scoped>
.home{
}
.home-position{
  width: 100%;
  z-index: 99;
  position: fixed;
  right: 0;
  top: 0;
  background:black;
  opacity:0.6
}
.shuffling{
  width: 100%;
  max-height:500px ;
}
.home-NavMenu{
  margin: 0 auto;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
}
.block{
  margin: 0;
  max-height: 500px;
}
.CompanySynopsis-image{
  margin: 20px;
}
.CompanySynopsis-text{
  padding: 0 20px;
}
.Company{
  max-width: 1000px;
  margin: 0 auto;
}
.CompanyIntroduction{
  font-size:35px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.CompanySynopsis{
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.CompanySynopsis>div{
  margin-right: 20px;
}
.CompanySynopsis div{
  font-size: 15px;
  color: #708090;
  line-height: 30px
}
.MainBusiness{
  padding: 40px;
  background: #232B2B;
  color: #ffffff;
}
.map {
  width: 782px;
  height:423px;
  max-width: 782px;
  max-height: 423px;
  margin: 0 auto;
}
.MapInformation{
  max-width: 990px;
  display: flex;
  margin: 0 auto;
  padding: 20px;
}
.MainBusiness-culture{
  max-width: 800px;
  margin: 0 auto;
}
.MainBusiness-flex{
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
}
.MainBusiness-image{
  width:50px;
  height: 50px;
  background: black;
  border-radius: 50%;
  margin-right: 20px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-content: center;
}
.MainBusiness-textList{
  width: calc(100% - 70px);
}
.MainBusiness-image .el-image{
  width: 40px;
  height: 40px;
  margin-top: 5px;
}
.Contactus{
  margin-bottom: 20px;
}
.ContactUs-right{
  margin-left: 20px;
  text-align: center;
}
.ContactUs-right-img{
  margin-bottom: 10px;
}
.ContactUs-image{
  width: 185px;
  height: 185px;
}
.ContactUs-text{
  text-align: center;
  margin: 0 auto;
}
.footer{
  width: 100%;
}
.footer-text{
  width: 100%;
  height: 50px;
  background: #232B2B;
  color: #708090;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 14px;
}
.footer-record{
  width: 100%;
  height: 40px;
  text-align: center;
  line-height: 40px;
  font-size: 14px;
}
@media only screen and (max-width:991px){

  .ContactUs-right{
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  .ContactUs-right-img{
    margin-right: 20px;
  }
}
@media only screen and (max-width:796px){
  .map {
    width: 600px;
    height:423px;
  }
  .ContactUs-right{
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  .ContactUs-right-img{
    margin-right: 20px;
  }
}
@media only screen and (max-width:621px){
  .map {
    width: 500px;
    height:423px;
  }
}
@media only screen and (max-width:521px){
  .map {
    width: 420px;
    height:423px;
  }
}
@media only screen and (max-width:421px){
  .map {
    width: 300px;
    height:423px;
  }
  .ContactUs-right-img{
    margin-right: 10px;
  }
  .ContactUs-image{
    width: 120px;
    height: 120px;
  }

}
</style>
