import { render, staticRenderFns } from "./CompanyHonor.vue?vue&type=template&id=30bb96cb&scoped=true&"
import script from "./CompanyHonor.vue?vue&type=script&lang=js&"
export * from "./CompanyHonor.vue?vue&type=script&lang=js&"
import style0 from "./CompanyHonor.vue?vue&type=style&index=0&id=30bb96cb&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30bb96cb",
  null
  
)

export default component.exports